import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Cloud Storage Website Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/cloud-storage-web-app/"
    metaDescription="Our cloud storage web app design template comes fully equipped with all the features you need to design your own cloud app. Try it now with Uizard."
    description="
    h2:Discover our cloud storage website design template
    <br/>
    Introducing Uizard's cloud storage web app design template. Our cloud storage <a:https://uizard.io/templates/>UI template</a> boasts a stunning and simplistic design, and is packed with all the must-have components and features for your cloud storage app design.
    <br/>
    h3:Uizard puts you on design cloud 9
    <br/>
    Our cloud storage website template comes with a stunning, clean aesthetic that is certain to appeal. If there are changes you want to make though, customization has never been so simple. With Uizard's easy and intuitive interface, you can customize and adapt our cloud storage web app design template to suit your needs.
    <br/>
    h3:Rapidly create your own cloud storage web app design
    <br/>
    At Uizard, our mission is to make the <a:https://uizard.io/prototyping/>UI prototyping</a> process quicker than ever before. You can create a functional prototype of your cloud web app in no time with our premade template. Looking to tweak the design to suit your own theme or brand? Editing our templates is super easy. Try Uizard now.
    "
    pages={[
      "A minimalist app homepage demonstrating clear intent through gorgeous typography and imagery",
      "A cloud storage interface screen boasting the core elements any successful cloud storage app requires",
    ]}
    projectCode="JJxOWGaOYlsgOn85G6vA"
    img1={data.image1.childImageSharp}
    img1alt="cloud sharing web app desing template cover"
    img2={data.image2.childImageSharp}
    img2alt="cloud sharing web app desing template upload screen"
    img3={data.image3.childImageSharp}
    img3alt="cloud sharing web app desing template favorites screen"
    img4={data.image4.childImageSharp}
    img4alt="cloud sharing web app desing template file screen"
    img5={data.image5.childImageSharp}
    img5alt="cloud sharing web app desing template overview"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/cloud-storage-web-app/cloud-sharing-web-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/cloud-storage-web-app/cloud-sharing-web-app-upload.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/cloud-storage-web-app/cloud-sharing-web-app-favorites.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/cloud-storage-web-app/cloud-sharing-web-app-file.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/cloud-storage-web-app/cloud-sharing-web-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
